import { db } from '@util/firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { UpdateSearchDocumentArgs } from './search.types';
import { logError } from '@util/logError';

export const saveSearchQuery = async (args: UpdateSearchDocumentArgs) => {
  if (!args.uid) return null;

  const searchesRef = doc(db, 'user_searches', args.uid);

  try {
    const docSnap = await getDoc(searchesRef);

    // If the document does not exist, create one with the search query.
    if (!docSnap.exists()) {
      await setDoc(searchesRef, {
        queries: [args.query],
      });
    }
    // If the document does exist, update the queries array using arrayUnion.
    else {
      const currentQueries = docSnap.data().queries || [];
      await updateDoc(searchesRef, {
        queries: [args.query, ...currentQueries],
      });
    }
  } catch (error) {
    logError(error);
  }
};
