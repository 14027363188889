import { useQuery } from '@tanstack/react-query';
import { getUserSearchHistory } from '@util/firestore/users';

const useSearchHistory = ({ uid }: { uid?: string }) => {
  const { data: previousSearches } = useQuery({
    queryKey: ['searchHistory', uid],
    queryFn: () => getUserSearchHistory(uid),
    enabled: !!uid,
  });
  if (!previousSearches) return [];
  return previousSearches;
};

export default useSearchHistory;
