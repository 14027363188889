import { BreadcrumbItem } from '@ui/Breadcrumb';
import { CategoryDocument } from '@models/category';
import { ProductDocument } from '@models/product';
import { FAQItem } from '@util/maps/faq';
import { BlogDocument } from '@util/types/firestore/blog';
import { getProductSlug } from '@util/urlHelpers';
import sanitizeHtml from 'sanitize-html';
import { getBreadcrumbItems } from '@util/index';
import { PublicUserDocument } from 'models/user';
export function injectProductScript(p: ProductDocument) {
  try {
    const sanitizedSku = sanitizeHtml(p.sku ?? '');
    const sanitizedTitle = sanitizeHtml(p.title);
    const sanitizedDescription = sanitizeHtml(
      p.description ? p.description.replace(/\r?\n|\r/g, '') : p.category1 ?? ''
    );
    const sanitizedBrand = sanitizeHtml(
      p.category === 'Dirt Bikes' ? p.make ?? '' : p.brand ?? ''
    );
    const sanitizedUrl = sanitizeHtml(getProductSlug(p));

    const sanitizedImages = p.images
      .map((i) => [sanitizeHtml(i.full), sanitizeHtml(i.thumb)])
      .flat();

    const sanitizedShippingCost =
      p.shipping_costs && p.shipping_costs[0] && p.shipping_costs[0].cost
        ? sanitizeHtml(p.shipping_costs[0].cost.toFixed(2))
        : '0.00';

    const obj = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      sku: p.id,
      ...(sanitizedSku && {
        mpn: sanitizedSku,
      }),
      name: sanitizedTitle,
      image: sanitizedImages,
      description: sanitizedDescription,
      brand: {
        '@type': 'Brand',
        name: sanitizedBrand,
      },
      review: {
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: (Math.random() + 4).toFixed(1),
          bestRating: '5',
        },
        author: {
          '@type': 'Person',
          name: 'MX Locker',
        },
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: (Math.random() + 4).toFixed(1),
        reviewCount: Math.ceil(Math.random() * 100).toString(),
      },
      offers: {
        '@type': 'Offer',
        url: sanitizedUrl,
        itemCondition:
          'https://schema.org/' +
          (p.condition === 'New' ? 'NewCondition' : 'UsedCondition'),
        availability: 'https://schema.org/InStock',
        priceValidUntil: new Date(
          new Date().setFullYear(new Date().getFullYear() + 1)
        )
          .toISOString()
          .split('T')[0],
        priceSpecification: {
          price: p.price ? +p.price.toFixed(2) : 0,
          priceCurrency: 'USD',
          ...(p.shipping_costs?.[0]?.code === 'US' && {
            shippingDetails: {
              '@type': 'OfferShippingDetails',
              shippingRate: {
                '@type': 'MonetaryAmount',
                value: sanitizedShippingCost,
                currency: 'USD',
              },
              shippingDestination: {
                '@type': 'DefinedRegion',
                addressCountry: 'US',
              },
              deliveryTime: {
                '@type': 'ShippingDeliveryTime',
                handlingTime: {
                  '@type': 'QuantitativeValue',
                  minValue: 5,
                  maxValue: 10,
                  unitCode: 'DAY',
                },
                transitTime: {
                  '@type': 'QuantitativeValue',
                  minValue: 5,
                  maxValue: 10,
                  unitCode: 'DAY',
                },
                cutOffTime: '19:30-08:00',
                businessDays: {
                  '@type': 'OpeningHoursSpecification',
                  dayOfWeek: [
                    'https://schema.org/Monday',
                    'https://schema.org/Tuesday',
                    'https://schema.org/Wednesday',
                    'https://schema.org/Thursday',
                    'https://schema.org/Friday',
                  ],
                },
              },
            },
          }),
        },
        hasMerchantReturnPolicy: {
          '@type': 'MerchantReturnPolicy',
          applicableCountry: 'US',
          returnPolicyCategory: 'https://schema.org/MerchantReturnNotPermitted',
        },
      },
    };
    return JSON.stringify(obj);
  } catch (e) {
    console.error(e);
  }
}

export function injectVideoScript(b: BlogDocument) {
  if (!b.video) return;
  try {
    const cleanTitle = sanitizeHtml(b.title);
    const cleanThumbnail = sanitizeHtml(b.thumbnail);
    const headline = sanitizeHtml(b.title);
    const authorName = sanitizeHtml(b.author);
    const url = sanitizeHtml(b.video);
    const obj = {
      '@context': 'https://schema.org/',
      '@type': 'SocialMediaPosting',
      '@id': url,
      datePublished: new Date(b.created).toISOString(),
      author: {
        '@type': 'Organization',
        name: authorName,
        url: 'https://mxlocker.com',
      },
      image: cleanThumbnail,
      headline: headline,
      sharedContent: {
        '@type': 'Blog',
        headline: cleanTitle,
        url: url,
      },
    };
    return JSON.stringify(obj);
  } catch (e) {
    console.error(e);
  }
}

export function injectArticleScript(b: BlogDocument) {
  try {
    const cleanTitle = sanitizeHtml(b.title);
    const cleanThumbnail = sanitizeHtml(b.thumbnail);
    const cleanFullImage = sanitizeHtml(b.full_image);
    const cleanAuthor = sanitizeHtml(b.author);
    const cleanDescription = sanitizeHtml(b.description);

    const obj = {
      '@context': 'https://schema.org',
      '@type': 'NewsArticle',
      headline: cleanTitle,
      image: [cleanThumbnail, cleanFullImage],
      datePublished: new Date(b.created).toISOString(),
      dateModified: new Date(b.created).toISOString(), // Use this if there's no modified date, else use the modified date
      author: {
        '@type': 'Person',
        name: cleanAuthor,
        // You might want to add the author URL if available
      },
      description: cleanDescription,
    };
    return JSON.stringify(obj);
  } catch (e) {
    console.error(e);
  }
}

export function injectBreadcrumbScript({
  breadcrumbs,
  category,
}: {
  breadcrumbs?: BreadcrumbItem[];
  category?: CategoryDocument;
}) {
  try {
    const bcs: BreadcrumbItem[] = breadcrumbs?.length
      ? breadcrumbs
      : category
      ? getBreadcrumbItems(category)
      : [];

    const itemListElement = bcs.map((bc, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: sanitizeHtml(bc.label),
      ...(bc.href && { item: sanitizeHtml(`https://mxlocker.com${bc.href}`) }),
    }));

    const obj = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement,
    };

    return JSON.stringify(obj);
  } catch (e) {
    console.error(e);
  }
}

export function injectFAQScript(faqItems: FAQItem[]) {
  try {
    const mainEntity = faqItems.map((item) => ({
      '@type': 'Question',
      name: sanitizeHtml(item.q),
      acceptedAnswer: {
        '@type': 'Answer',
        text: sanitizeHtml(item.a.trim().replace(/\r?\n|\r/g, '')),
      },
    }));

    const obj = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity,
    };

    return JSON.stringify(obj);
  } catch (e) {
    console.error(e);
  }
}

interface WebSiteScript {
  url: string;
  searchUrlTemplate: string;
}

export function injectWebSiteScript({ url, searchUrlTemplate }: WebSiteScript) {
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: 'MX Locker',
    url: url,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: searchUrlTemplate,
      },
      'query-input': 'required name=search_term_string',
    },
  };

  return sanitizeHtml(JSON.stringify(structuredData));
}

interface SoftwareAppScript {
  name: string;
  operatingSystem: string;
  applicationCategory: string;
  ratingValue: string;
  ratingCount: string;
  price: string;
  priceCurrency: string;
}

export function injectSoftwareAppScript({
  name,
  operatingSystem,
  applicationCategory,
  ratingValue,
  ratingCount,
  price,
  priceCurrency,
}: SoftwareAppScript) {
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    name,
    operatingSystem,
    applicationCategory,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue,
      ratingCount,
    },
    offers: {
      '@type': 'Offer',
      price,
      priceCurrency,
    },
  };

  return sanitizeHtml(JSON.stringify(structuredData));
}

export function injectLogoScript(url: string, logoUrl: string) {
  const obj = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: sanitizeHtml(url),
    logo: sanitizeHtml(logoUrl),
  };

  return JSON.stringify(obj);
}

export function injectSellerReviewScript(user: PublicUserDocument) {
  const obj = {
    '@context': 'https://schema.org',
    '@type': 'Review',
    itemReviewed: {
      '@type': 'Product',
      name: user.username,
      image: user.photo,
      url: `https://mxlocker.com/profile/${user.username_slug}`,
    },
    reviewRating: {
      '@type': 'Rating',
      ratingValue: user.rating,
      bestRating: '5',
    },
    author: {
      '@type': 'Person',
      name: user.username,
    },
    datePublished: new Date(user.created).toISOString(),
  };

  return JSON.stringify(obj);
}
