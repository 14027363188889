import SafeImage from '@ui/SafeImage';
import { timeAgo } from '@util/index';
import { useNotification } from 'context/NotificationContext';
import { NotificationDocument } from '@models/notification';
import Link from 'next/link';
import { NotificationUnreadDot } from './NotificationUnreadDot';
import { useQueryClient } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import { useState } from 'react';

const NotificationIcon = dynamic(() => import('./NotificationIcon'), {
  ssr: false,
});

function NotificationItem({
  notification,
  preventDefault,
}: {
  notification: NotificationDocument;
  preventDefault?: boolean;
}) {
  const { setShow, markAsReadMutation } = useNotification();
  const queryClient = useQueryClient();

  return (
    <Link
      href={notification.web_url}
      onClick={(e) => {
        if (preventDefault) {
          e.preventDefault();
          return;
        }
        // mark notification as read
        markAsReadMutation([notification.id]);
        queryClient.invalidateQueries({
          queryKey: ['notifications'],
        });
        setShow(false);
      }}
      className="grid grow grid-cols-[44px_1fr] gap-[1.2rem] px-[0.8rem]"
    >
      <div className="flex items-center justify-center">
        <NotificationIcon notification={notification} />
      </div>
      <div>
        <div className="flex w-full justify-between">
          <span className="text-[1.4rem] font-semibold">
            {notification.title}
          </span>
          <span className="text-[1.2rem] font-semibold text-gray-400">
            {!notification.read && <NotificationUnreadDot />}
            {timeAgo(notification.created)?.replace('ago', '')}
          </span>
        </div>
        <span className="line-clamp-1 text-[1.2rem] sm:line-clamp-2 ">
          {notification.message}
        </span>
      </div>
    </Link>
  );
}

export { NotificationItem };
