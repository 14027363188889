import React from 'react';
import Button from '@ui/Button';
import Link from 'next/link';
import NavMenu from './NavMenu';
import { PersonIcon } from '@c/icons';
import { useAuth } from 'context/AuthContext';
import categories from './utils/categories';
import NavMenuSection from './NavMenuSection';
import { useParams } from 'next/navigation';
import { chunk } from 'lodash';

export default function CategoryBar() {
  const { userDoc } = useAuth();
  const params = useParams();

  const isCategoryActive = (category: string) => {
    const allCategories = params?.categorySlug;

    return allCategories?.includes(category);
  };

  return (
    <div className="z-50 mx-auto hidden w-full max-w-content items-center justify-between gap-[1.6rem] overflow-hidden bg-brand-white text-left font-medium text-black/[0.87] lg:flex [&>*]:text-[1.4rem]">
      {/* Left items */}
      <div className="flex gap-x-[2.4rem] space-x-8 whitespace-nowrap p-0 [&>*]:text-left [&>*]:uppercase">
        {categories
          .filter((cat) => !cat.excludeFromNav)
          .map((category, index) => (
            <NavMenu
              key={category.label}
              label={category.label}
              href={category.href}
              userDoc={userDoc}
              componentIndex={index}
            >
              <div className="flex w-full flex-wrap  gap-y-16 py-8">
                {category.sections?.map((section) => {
                  // group links into max 10
                  const linkGroups = chunk(section.links ?? [], 10);
                  return (
                    <NavMenuSection
                      key={section.label}
                      label={section.label}
                      href={section.href}
                    >
                      <div className="flex gap-[1.6rem]">
                        {linkGroups.map((group, i) => (
                          <div className="flex flex-col gap-y-4 pt-4" key={i}>
                            {group.map((link) => (
                              <Link
                                key={link.label}
                                className={`font-light capitalize ${
                                  isCategoryActive(link.label.toLowerCase())
                                    ? 'text-brand-secondary'
                                    : 'text-black hover:text-brand-secondary'
                                }`}
                                href={link.href}
                              >
                                {link.label}
                              </Link>
                            ))}
                          </div>
                        ))}
                      </div>
                    </NavMenuSection>
                  );
                })}
              </div>
            </NavMenu>
          ))}
      </div>
      <div className="flex items-center space-x-4">
        {/* <Button
          text="FOR YOU"
          href="/for-you"
          type="text"
          noXPadding
          width="small"
          leadingIcon={<PersonIcon width={16} height={16} fill="#C41719" />}
        /> */}
        <Button
          text="ON SALE"
          href="/shop/sale"
          type="text"
          noXPadding
          width="small"
          leadingIcon={<SaleTag />}
        />
      </div>
    </div>
  );
}

const SaleTag = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.66668 4.66659C3.40146 4.66659 3.14711 4.56123 2.95957 4.37369C2.77203 4.18616 2.66668 3.9318 2.66668 3.66659C2.66668 3.40137 2.77203 3.14701 2.95957 2.95948C3.14711 2.77194 3.40146 2.66659 3.66668 2.66659C3.93189 2.66659 4.18625 2.77194 4.37378 2.95948C4.56132 3.14701 4.66668 3.40137 4.66668 3.66659C4.66668 3.9318 4.56132 4.18616 4.37378 4.37369C4.18625 4.56123 3.93189 4.66659 3.66668 4.66659ZM14.2733 7.71992L8.27334 1.71992C8.03334 1.47992 7.70001 1.33325 7.33334 1.33325H2.66668C1.92668 1.33325 1.33334 1.92659 1.33334 2.66659V7.33325C1.33334 7.69992 1.48001 8.03325 1.72668 8.27325L7.72001 14.2733C7.96668 14.5133 8.30001 14.6666 8.66668 14.6666C9.03334 14.6666 9.36668 14.5133 9.60668 14.2733L14.2733 9.60659C14.52 9.36659 14.6667 9.03325 14.6667 8.66659C14.6667 8.29325 14.5133 7.95992 14.2733 7.71992Z"
      fill="#C41719"
    />
  </svg>
);
