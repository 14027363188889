import { NotificationDocument } from '@models/notification';
import { FirebaseCallable, db, functions, httpsCallable } from '@util/firebase';
import {
  CollectionReference,
  collection,
  doc,
  endBefore,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';

export const notifsRef = collection(
  db,
  'notifications'
) as CollectionReference<NotificationDocument>;

export async function getNotifications(
  uid: string
): Promise<NotificationDocument[]> {
  if (!uid) return Promise.resolve([]);
  const q = query(
    notifsRef,
    where('uid', '==', uid),
    orderBy('created', 'desc'),
    limit(20)
  );
  const snap = await getDocs(q);
  if (snap.empty) return [];
  return snap.docs.map((d) => d.data());
}

export async function getNotificationsPaginated({
  uid,
  limit: l,
  filter,
  lastDoc,
  direction,
}: {
  uid: string;
  lastDoc: NotificationDocument | null;
  limit: number;
  filter?: string;
  direction?: 'next' | 'prev';
}): Promise<NotificationDocument[]> {
  if (!uid) return Promise.resolve([]);
  const q = query(
    notifsRef,
    where('uid', '==', uid),
    ...(filter !== 'all' ? [where('key', '==', filter)] : []),
    orderBy('created', 'desc'),
    ...(lastDoc
      ? direction === 'next'
        ? [startAfter(lastDoc?.created)]
        : [endBefore(lastDoc?.created)]
      : []),
    limit(l)
  );
  const snap = await getDocs(q);
  if (snap.empty) return [];
  return snap.docs.map((d) => d.data());
}

export function getRealtimeNotificationsQuery(uid: string) {
  return query(
    notifsRef,
    where('uid', '==', uid),
    where('created', '>', Date.now()),
    orderBy('created', 'desc'),
    limit(20)
  );
}

export async function markNotificationAsRead(ids: string[]) {
  const promises = ids.map((id) => {
    const docRef = doc(notifsRef, id);
    return updateDoc(docRef, { read: true });
  });
  await Promise.all(promises);
}

export async function deleteNotifications({
  ids,
  uid,
}: {
  ids: string[];
  uid: string;
}) {
  const promises = ids.map((id) => {
    const docRef = doc(notifsRef, id);
    return updateDoc(docRef, {
      uid: `deleted-${uid}`,
      deleted: true,
    });
  });
  await Promise.all(promises);
}

export async function markAllAsRead() {
  await httpsCallable(
    functions,
    FirebaseCallable.clearNotifications
  )({
    update: true,
  });
}

export async function clearAllNotifications() {
  await httpsCallable(functions, FirebaseCallable.clearNotifications)();
}
