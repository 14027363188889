import { useQuery } from '@tanstack/react-query';
import { fetchSuggestions } from '../service';

export const useQuerySuggestions = (query: string) => {
  const { data: suggestions = [], isLoading } = useQuery({
    queryKey: ['suggestions', query],
    queryFn: () => fetchSuggestions(query),
  });
  return {
    suggestions,
    isLoading,
  };
};
