export default function DeliveredIcon({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11 2C10.8 2 10.6 2.1 10.4 2.2L2.5 6.6C2.2 6.8 2 7.1 2 7.5V16.5C2 16.9 2.2 17.2 2.5 17.4L10.4 21.8C10.6 21.9 10.8 22 11 22C11.2 22 11.4 21.9 11.6 21.8L12.5 21.3C12.2 20.7 12.1 20 12 19.3V12.6L18 9.2V13C18.7 13 19.4 13.1 20 13.3V7.5C20 7.1 19.8 6.8 19.5 6.6L11.6 2.2C11.4 2.1 11.2 2 11 2ZM11 4.2L17 7.5L15 8.6L9.1 5.2L11 4.2ZM7.1 6.3L13 9.8L11 10.9L5 7.5L7.1 6.3ZM4 9.2L10 12.6V19.3L4 15.9V9.2ZM20.3 15.8L16.7 19.4L15.1 17.8L14 19L16.8 22L21.6 17.2L20.3 15.8Z"
        fill="#1E7A1E"
      />
    </svg>
  );
}
