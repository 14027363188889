import {
  useFloating,
  useInteractions,
  useClick,
} from '@floating-ui/react-dom-interactions';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { Fragment } from 'react';
interface BaseMenuProps {
  triggerElement: React.ReactNode;
  children: React.ReactNode;
}

const BaseMenu = ({ triggerElement, children }: BaseMenuProps) => {
  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: 'bottom-end',
  });

  const { getReferenceProps } = useInteractions([useClick(context)]);

  return (
    <div>
      <Popover className="relative flex items-center">
        {({ open }) => (
          <>
            <PopoverButton
              {...getReferenceProps()}
              ref={reference}
              className="outline-none"
            >
              {triggerElement}
            </PopoverButton>
            <Transition show={open} as={Fragment}>
              <PopoverPanel
                static
                ref={floating}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  width: 'max-content',
                  zIndex: 100,
                }}
              >
                {children}
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default BaseMenu;
