import { SvgIconProps } from '@c/icons/types';
import { Facebook, Instagram, Tiktok, Youtube } from '@c/icons/socials';
import Link from 'next/link';

type SocialVariant = 'facebook' | 'instagram' | 'youtube' | 'tiktok';
interface SocialLinkProps {
  variant: SocialVariant;
}

const getAssets = (
  v: SocialVariant
): {
  href: string;
  Icon: ({ svgProps, pathProps }: SvgIconProps) => JSX.Element;
} => {
  if (v === 'facebook')
    return {
      href: 'https://www.facebook.com/mxlocker',
      Icon: Facebook,
    };
  if (v === 'instagram')
    return {
      href: 'https://www.instagram.com/mxlocker',
      Icon: Instagram,
    };
  if (v === 'tiktok')
    return {
      href: 'https://www.tiktok.com/@mxlocker',
      Icon: Tiktok,
    };
  if (v === 'youtube')
    return {
      href: 'https://www.youtube.com/@mxlocker',
      Icon: Youtube,
    };
  return { href: '/', Icon: Facebook };
};

const SocialLink = ({ variant }: SocialLinkProps) => {
  const { href, Icon } = getAssets(variant);
  return (
    <Link
      className="flex h-[2.4rem] w-[2.4rem] items-center justify-center"
      href={href}
      aria-label={variant}
    >
      <Icon
        svgProps={{
          className:
            'fill-brand-black hover:fill-brand-secondary transition-colors flex items-center justify-center',
        }}
      />
    </Link>
  );
};

export default SocialLink;
