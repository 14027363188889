'use client';
import { AppFeatureSection } from '@c/appFeatureSection';
import SafeImage from '@ui/SafeImage';
import Link from 'next/link';

// images
import AppStore from '@i/brands/AppStore.svg';
import GooglePlayStore from '@i/brands/GooglePlayStore.svg';
import Listing from '@i/get-app/features/listing.svg';
import RealtimeTracking from '@i/get-app/features/realTimeTracking.svg';
import Shopping from '@i/get-app/features/shopping.svg';
import IPhoneLarge from '@i/get-app/iPhone-l.png';
import IPhoneSmall from '@i/get-app/iPhone-s.png';
import ScreenLarge from '@i/get-app/phoneScreenBack.png';
import ScreenSmall from '@i/get-app/phoneScreenFront.png';
import { logEvent } from '@util/analytics';
import { usePathname } from 'next/navigation';

export const AppStoreButtons = () => {
  const pathname = usePathname();
  return (
    <div className="my-[1.8rem] w-full flex-1 text-[1.8rem] font-normal">
      <div className="flex justify-center gap-[2.4rem]">
        <Link
          href="https://apps.apple.com/us/app/mx-locker/id1543819006"
          onClick={() => {
            logEvent('sent_to_app_store', {
              route: pathname?.includes('order-confirmation')
                ? 'order-confirmation'
                : pathname,
              platform: 'ios',
            });
          }}
          target="_blank"
          className="rounded-brand border-[1px] border-white"
        >
          <SafeImage src={AppStore} alt="AppStore" className="w-[17rem]" />
        </Link>
        <Link
          href="https://play.google.com/store/apps/details?id=com.mx.locker&pli=1"
          onClick={() => {
            logEvent('sent_to_app_store', {
              route: pathname?.includes('order-confirmation')
                ? 'order-confirmation'
                : pathname,
              platform: 'android',
            });
          }}
          target="_blank"
          className="rounded-brand border-[1px] border-white"
        >
          <SafeImage
            src={GooglePlayStore}
            alt="GooglePlayStore"
            className="w-[17rem]"
          />
        </Link>
      </div>
    </div>
  );
};

const GetTheApp = () => {
  return (
    <>
      <div className="w-full">
        <div className="w-full bg-brand-darkest-white px-[1rem] md:px-[12rem]">
          <div className="mx-auto flex w-full max-w-[100rem] flex-col px-[5rem] pt-[7rem] text-brand-extreme-lightest-black md:flex-row md:px-0">
            <div className="mt-[4rem] w-full flex-1 text-[4rem] font-semibold">
              <h1>MX Locker App</h1>
              <div className="mt-[1.6rem] w-full flex-1 text-[1.8rem] font-normal">
                Download the app to buy and sell riding gear, bike parts, and
                dirt bikes straight from your phone.
              </div>
              <AppStoreButtons />
            </div>

            <div className="relative mt-[4rem] hidden w-full flex-1 text-[3.2rem] font-semibold lg:flex">
              <SafeImage
                src={IPhoneLarge}
                alt="IPhoneLarge"
                className="absolute bottom-0 right-0 z-0 min-w-[22rem]"
              />
              <SafeImage
                src={ScreenLarge}
                alt="ScreenLarge"
                className="z-1 absolute bottom-0 right-[1rem] min-w-[20rem]"
              />
              <SafeImage
                src={IPhoneSmall}
                alt="IPhoneSmall"
                className="z-2 absolute bottom-0 right-[15rem] min-w-[22rem]"
              />
              <SafeImage
                src={ScreenSmall}
                alt="ScreenSmall"
                className="z-3 absolute bottom-0 right-[16rem] min-w-[20rem]"
              />
            </div>
          </div>
        </div>

        <div className="mx-auto flex max-w-[120rem] flex-col px-[1rem] text-brand-white md:px-[12rem]">
          <div className="mt-[1rem]  text-brand-extreme-lightest-black md:mt-[4rem]">
            <div className="text-center text-[3.2rem] font-semibold text-brand-extreme-lightest-black">
              The best of MX Locker anytime, anywhere.
            </div>
            <div className="mt-[1.6rem] text-center text-[1.6rem] font-normal text-brand-extreme-lightest-black">
              These features make it easy to buy and sell riding gear, bike
              parts, and dirt bikes.
            </div>
          </div>
        </div>

        <div className="mx-auto mb-[10rem] w-full max-w-[120rem]  px-[1rem] text-brand-white md:px-[12rem]">
          <div className="mt-[4rem] flex flex-col text-brand-extreme-lightest-black md:mt-[8.8rem] md:flex-row md:justify-between md:gap-[10rem]">
            <AppFeatureSection
              icon={Listing}
              title="Easily manage listings"
              description="Easy ways to create and manage listings. Sell quickly and easily on MX Locker."
            />
            <AppFeatureSection
              icon={Shopping}
              title="Endless shopping opportunities"
              description="Shop thousands of listings for your favorite riding gear, bike parts and dirt bikes."
            />
          </div>
          <div className="mt-[4rem] flex flex-col text-brand-extreme-lightest-black md:mt-[8.8rem] md:flex-row md:justify-between md:gap-[10rem]">
            <AppFeatureSection
              icon={Shopping}
              title="Seamless communication"
              description="Make deals happen! Chat and negotiate directly with buyers and sellers."
            />
            <AppFeatureSection
              icon={RealtimeTracking}
              title="Real-time tracking updates"
              description="Get immediate tracking updates for your orders, keeping you informed every step of the way."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GetTheApp;
