import Spinner from '@ui/Spinner';

const LoadingSpinner = () => (
  <div className="flex h-[calc(100vh_-_8rem)] w-full items-center justify-center sm:h-[calc(100vh_-_15rem)]">
    <div className="h-[6.4rem] w-[6.4rem]">
      <Spinner />
    </div>
  </div>
);

export default LoadingSpinner;
