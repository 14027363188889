import LoadingSpinner from '@c/LoadingSpinner';
import ConfirmModal from '@c/modals/ConfirmModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import Button from '@ui/Button';
import Checkbox from '@ui/Checkbox';
import SafeImage from '@ui/SafeImage';
import { logEvent } from '@util/analytics';
import {
  getDraftProductsBySellerId,
  reactivateProductDoc,
} from '@util/firestore/products';
import { logError } from '@util/logError';
import { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';
import dynamic from 'next/dynamic';
import React from 'react';
const BaseModal = dynamic(() => import('@c/modals/BaseModal'), { ssr: false });

const WelcomeBackModal = () => {
  const { userDoc, showWelcomeBackModal, setShowWelcomeBackModal } = useAuth();
  const { data: products, isLoading } = useQuery({
    queryKey: ['listings', userDoc?.uid],
    queryFn: () => getDraftProductsBySellerId(userDoc!.uid),
    enabled: !!userDoc?.uid && showWelcomeBackModal,
  });
  const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);
  const [showWarning, setShowWarning] = React.useState(false);
  const { showSuccessToast, showErrorToast } = useToastContext();
  const [error, setError] = React.useState<string | null>(null);

  const submitProductsMutation = useMutation({
    mutationKey: ['submitProducts', selectedProducts.join(', ')],
    mutationFn: () => submitProducts(selectedProducts),
    onSuccess: () => {
      if (selectedProducts.length > 0) {
        showSuccessToast(
          `Successfully re-listed ${selectedProducts.length} product${
            selectedProducts.length > 1 ? 's' : ''
          }.`
        );
      } else {
        showErrorToast('No products re-listed.');
      }
      logEvent('welcome_back_modal_relist', {
        uid: userDoc?.uid,
        productIds: selectedProducts,
      });
      setSelectedProducts([]);
      setShowWelcomeBackModal(false);
    },
    onError: (e) => {
      setError(
        'Something went wrong. Please refresh this page and contact support. You may still update your products in the listings section of your dashboard.'
      );
      logError(
        e,
        `Error re-listing products from welcome back modal for user ${userDoc?.uid}`
      );
    },
  });

  return !!(showWelcomeBackModal && products?.length) ? (
    <BaseModal
      isOpen={!!(showWelcomeBackModal && products?.length)}
      disableBackdropDismiss
      hideCloseIcon
      noPadding
    >
      <div className="relative mb-4 h-[20vh]">
        <SafeImage
          alt="header image"
          src="/login.webp"
          fill
          key="header image"
          style={{ objectFit: 'cover' }}
        />
      </div>

      <div className="flex flex-col items-center justify-center gap-[1.6rem] px-4 pb-[1.6rem] sm:px-0">
        <h1 className="text-[2.4rem] font-semibold">Welcome Back! &#128075;</h1>
        <span className="max-w-[50rem] text-center">
          Since you haven&apos;t been on MX Locker in a while, we had to put
          your listings into draft mode.
        </span>
        <strong className="text-center">
          Please select the listings you wish to reactivate.
        </strong>
        {error && (
          <span className="text-center text-[1.4rem] text-red-500">
            {error}
          </span>
        )}

        <div className="flex h-[45vh] w-[80%] flex-col gap-[1.6rem] overflow-y-auto rounded-sm  p-4 px-[1.6rem]  shadow-lg">
          {isLoading && <LoadingSpinner />}
          {products?.map((product) => (
            <div
              className="flex w-full items-center gap-[1.6rem] px-[1.6rem] "
              key={product.id}
            >
              <Checkbox
                selected={selectedProducts.includes(product.id)}
                onChange={() => {
                  let newSelectedProducts: string[] = [];
                  if (selectedProducts.includes(product.id)) {
                    newSelectedProducts = selectedProducts.filter(
                      (id) => id !== product.id
                    );
                  } else {
                    newSelectedProducts = [...selectedProducts, product.id];
                  }
                  setSelectedProducts(newSelectedProducts);
                }}
                label={''}
              />
              <SafeImage
                width={500}
                height={500}
                alt={product.title}
                src={product.thumbnail}
                className="h-[5rem] w-[5rem]"
              />
              <span>{product.title}</span>
            </div>
          ))}
        </div>
        <Button
          text="Submit"
          type="secondary"
          onClick={() => {
            if (selectedProducts.length === 0) {
              setShowWarning(true);
            } else {
              submitProductsMutation.mutate();
            }
          }}
        />
      </div>
      <ConfirmModal
        title={'Are you sure'}
        body={'None of your products will be visible to buyers.'}
        isOpen={showWarning}
        buttonText="Continue"
        dismiss={(confirm) => {
          if (confirm) {
            submitProductsMutation.mutate();
          } else {
            setShowWarning(false);
          }
        }}
      />
    </BaseModal>
  ) : null;
};

async function submitProducts(productIds: string[]) {
  const promises = productIds.map((id) => reactivateProductDoc(id));
  await Promise.all(promises);
}

export default WelcomeBackModal;
