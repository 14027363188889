import React from 'react';

const BellIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M18 8.6C18 7.11479 17.3679 5.69041 16.2426 4.6402C15.1174 3.59 13.5913 3 12 3C10.4087 3 8.88258 3.59 7.75736 4.6402C6.63214 5.69041 6 7.11479 6 8.6C6 15.1333 3 17 3 17H21C21 17 18 15.1333 18 8.6Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="bevel"
      />
      <path
        d="M14 20C13.7968 20.3042 13.505 20.5566 13.154 20.7321C12.803 20.9076 12.4051 21 12 21C11.5949 21 11.197 20.9076 10.846 20.7321C10.495 20.5566 10.2032 20.3042 10 20"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="bevel"
      />
    </svg>
  );
};

export default BellIcon;
