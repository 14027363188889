import Avatar from '@ui/Avatar';
import { ChevronRightIcon } from './icons';
import { useAuth } from 'context/AuthContext';
import Link from 'next/link';

const ProfileItem = ({ dismiss }: { dismiss: () => void }) => {
  const { userDoc } = useAuth();
  if (!userDoc) return null;

  return (
    <Link
      href={'/dashboard/my-profile'}
      className="flex w-full items-center justify-between"
      onClick={dismiss}
    >
      <div className="flex items-center gap-[1.2rem] px-[1rem] py-[2rem]">
        <Avatar size="medium" user={userDoc} />
        <div className="flex flex-col justify-between">
          <span className="text-[1.7rem] font-semibold text-brand-black">
            {userDoc.username}
          </span>
          <div className="flex items-center text-brand-secondary lg:hidden">
            <span className="text-[1.3rem] font-medium">View Profile</span>
            <ChevronRightIcon className={'h-[2rem] w-[2rem]'} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProfileItem;
