'use client';

import { getHostUrl } from '@util/index';
import { usePathname, useSearchParams } from 'next/navigation';
import React from 'react';

const CanonicalUrl = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  // set canonical url for pages with the these query params to prevent duplicate content
  let canonicalUrl =
    searchParams?.get('redirect') || searchParams?.get('radius')
      ? `${getHostUrl()}${pathname?.split('?')[0]}`
      : `${getHostUrl()}${pathname}`;

  if (searchParams?.get('shipTo') || searchParams?.get('shipFrom')) {
    // remove shipTo and shipFrom from canonical url
    const url = new URL(canonicalUrl);
    url.searchParams.delete('shipTo');
    url.searchParams.delete('shipFrom');
    canonicalUrl = url.toString();
  }
  if (
    !searchParams?.get('page') ||
    searchParams?.get('shipTo') ||
    searchParams?.get('shipFrom')
  )
    return <link rel="canonical" href={canonicalUrl} />;
  else return null;
};

export default CanonicalUrl;
