import { NotificationDocument } from '@models/notification';
import { groupNotifications } from '../utils';
import { NotificationItem } from './NotificationItem';

const NotificationList = ({
  notifications,
}: {
  notifications: NotificationDocument[];
}) => {
  // group notification by date -> today, this week, last week, later
  const groupedNotifications = groupNotifications(notifications);
  return (
    <div className="flex w-full flex-col gap-[1.6rem]">
      {groupedNotifications.today.length > 0 && (
        <NotificationSection
          title="Today"
          key="today"
          notifications={groupedNotifications.today}
        />
      )}
      {groupedNotifications.thisWeek.length > 0 && (
        <NotificationSection
          title="This Week"
          key={'this week'}
          notifications={groupedNotifications.thisWeek}
        />
      )}
      {groupedNotifications.lastWeek.length > 0 && (
        <NotificationSection
          title="Last week"
          key={'last week'}
          notifications={groupedNotifications.lastWeek}
        />
      )}
      {groupedNotifications.later.length > 0 && (
        <NotificationSection
          title="Later"
          key={'later'}
          notifications={groupedNotifications.later}
        />
      )}
    </div>
  );
};

function NotificationSection({
  title,
  notifications,
}: {
  title: string;
  notifications: NotificationDocument[];
}) {
  return (
    <div className="flex flex-col gap-[1.6rem]">
      <span className="px-4 text-[1.4rem] font-semibold">{title}</span>
      {notifications.map((notification) => (
        <NotificationItem key={notification.id} notification={notification} />
      ))}
    </div>
  );
}

export { NotificationList };
