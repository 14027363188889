const BellRingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
    >
      <path
        d="M20.0669 43.0045L1.49443 24.432C0.647689 23.5853 0.647689 22.2124 1.49443 21.3655C2.34117 20.5188 3.71411 20.5188 4.56094 21.3655L23.1333 39.9379C23.9801 40.7847 23.9801 42.1576 23.1333 43.0044C22.2866 43.8512 20.9136 43.8512 20.0669 43.0045Z"
        fill="#D9D9D9"
      />
      <path
        d="M28.6055 12.9485L34.4155 6.15222C35.4719 4.91661 37.357 4.84296 38.5065 5.99246C39.656 7.14196 39.5824 9.02708 38.3467 10.0834L31.5496 15.8943"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M12.7533 35.746C12.6082 36.2644 12.3319 36.7538 11.924 37.1616C10.6582 38.4275 8.60579 38.4275 7.34002 37.1616C6.07416 35.8957 6.07416 33.8434 7.34002 32.5776C7.73559 32.182 8.20799 31.91 8.70918 31.7617"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M20.0669 43.0045L1.49443 24.432C0.647689 23.5853 0.647689 22.2124 1.49443 21.3655C2.34117 20.5188 3.71411 20.5188 4.56094 21.3655L23.1333 39.9379C23.9801 40.7847 23.9801 42.1576 23.1333 43.0044C22.2866 43.8512 20.9136 43.8512 20.0669 43.0045Z"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M23.4086 40.2116C23.0089 37.6148 23.8704 34.9831 25.7282 33.1253L30.1608 28.6926C34.1242 24.7292 34.1242 18.3032 30.1608 14.3398C26.1974 10.3763 19.7714 10.3763 15.808 14.3398L11.3754 18.7724C9.51759 20.6302 6.88584 21.4917 4.28906 21.092"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M24.9171 6.50493C21.6403 4.6131 17.4504 5.73579 15.5586 9.01259"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M27.0305 2.84531C21.7323 -0.213634 14.9574 1.60171 11.8984 6.89993"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M37.9959 19.582C39.8878 22.8587 38.7651 27.0487 35.4883 28.9405"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M41.6562 17.4688C44.7151 22.767 42.8998 29.5419 37.6016 32.6008"
        stroke="black"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { BellRingIcon };
