import BaseDrawer from '@c/drawers/BaseDrawer';
import { CloseIcon } from '@c/icons';
import { useNotification } from 'context/NotificationContext';
import { useRouter } from 'next/navigation';
import { BellRingIcon } from './BellRingIcon';
import { NotificationList } from '@features/notifications';

const NotificationDrawer = () => {
  const { show, setShow, notifications, isLoading } = useNotification();
  const { push } = useRouter();
  return (
    <BaseDrawer dismiss={() => setShow(false)} show={show} side="right">
      <div className="flex h-full w-full flex-col">
        {/* Header */}
        <div className="flex w-full items-center justify-between p-[1.6rem]">
          <span className="text-[2.4rem] font-semibold">Notifications</span>
          <button onClick={() => setShow(false)}>
            <CloseIcon />
          </button>
        </div>
        {/*  */}
        {/* Body */}
        <div className="flex grow flex-col items-center  overflow-y-auto">
          {isLoading && (
            <div className="my-auto flex flex-col items-center gap-[3.2rem]">
              <BellRingIcon />
              <span>Loading...</span>
            </div>
          )}
          {notifications?.length === 0 && (
            <div className="my-auto flex flex-col items-center gap-[3.2rem]">
              <BellRingIcon />
              <span>You have no notifications</span>
            </div>
          )}
          {!!notifications?.length && notifications.length > 0 && (
            <div className="flex w-full flex-col items-center  gap-[3.2rem] overflow-y-auto pb-4">
              <NotificationList notifications={notifications} />
            </div>
          )}
        </div>
        {/* Footer */}
        <button
          className="border-t-[1px] border-black p-[1.2rem] font-semibold text-brand-secondary "
          onClick={() => {
            setShow(false);
            push('/dashboard/notifications');
          }}
        >
          View All Notifications
        </button>
      </div>
    </BaseDrawer>
  );
};

export { NotificationDrawer };
