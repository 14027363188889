import { useNotification } from 'context/NotificationContext';
import BellIcon from './BellIcon';

const NotificationButton = () => {
  const { setShow, unreadNotificationsCount } = useNotification();
  return (
    <button
      onClick={() => setShow(true)}
      className="relative flex items-center justify-center"
    >
      {unreadNotificationsCount > 0 && (
        <div className="absolute bottom-2 right-0 flex h-8 w-8 items-center justify-center rounded-full border-[.2rem] border-brand-white bg-brand-secondary text-[1rem] font-medium text-brand-white lg:bottom-0 -top-2 sm:top-2">
          {unreadNotificationsCount}
        </div>
      )}

      <BellIcon className="h-[3rem] w-[3rem]" />
    </button>
  );
};

export { NotificationButton };
