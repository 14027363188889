'use client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AttributionProvider from 'context/AttributionContext';
import AuthProvider from 'context/AuthContext';
import { ChatProvider } from 'context/ChatContext';
import { NotificationProvider } from 'context/NotificationContext';
import { SearchProvider } from 'context/SearchContext';
import { ShoppingCartProvider } from 'context/ShoppingCartContext';
import { StripeProvider } from 'context/StripeContext';
import { ToastProvider } from 'context/ToastContext';
import { WelcomeBackModal } from 'features/welcomeBack';
import { ReactNode } from 'react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});
// PUT ALL OF YOUR CONTEXT PROVIDERS HERE
function ProviderProvider({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AttributionProvider>
        <SearchProvider>
          <AuthProvider>
            <StripeProvider>
              <ToastProvider>
                <ShoppingCartProvider>
                  <NotificationProvider>
                    <ChatProvider>
                      {children}
                      <WelcomeBackModal />
                    </ChatProvider>
                  </NotificationProvider>
                </ShoppingCartProvider>
              </ToastProvider>
            </StripeProvider>
          </AuthProvider>
        </SearchProvider>
      </AttributionProvider>
    </QueryClientProvider>
  );
}

export default ProviderProvider;
