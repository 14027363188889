import { client } from '@util/getTypesense';

export const fetchSuggestions = async (query: string) => {
  const res = await client
    .collections<{ q: string }>('product_queries')
    .documents()
    .search({
      q: query,
      query_by: 'q',
      sort_by: 'count:desc',
      per_page: 7,
    });
  return res.hits;
};
