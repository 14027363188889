'use client';
import {
  CloseIcon,
  HeartIcon,
  LogoIcon,
  MenuIcon,
  PersonOutlineIcon,
} from '@c/icons';
import { NotificationButton } from '@features/notifications';
import { UserIcon } from '@heroicons/react/20/solid';
import Button from '@ui/Button';
import SearchBox from '@ui/SearchBox';
import { isLargeDesktop, isMobile } from '@util/index';
import { useAuth } from 'context/AuthContext';
import { useSearch } from 'context/SearchContext';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Suspense, useEffect, use, useState } from 'react';
import MenuDrawer from './drawers/MenuDrawer';
import ChatButton from './icons/buttons/ChatButton';
import ShoppingCartButton from './icons/buttons/ShoppingCartButton';
import UserMenu from './menus/UserMenu';
import CategoryBar from './navigation/CategoryBar';
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web';
import { SearchClient } from 'typesense-instantsearch-adapter';
import { searchClient } from '@util/getTypesense';
import SearchResultsPreview from './SearchResultsPreview';
import { motion, useAnimationControls } from 'framer-motion';

const Navbar = () => {
  const { user, userDoc } = useAuth();
  const {
    showMobileSearchbar,
    setShowMobileSearchbar,
    searchTerm,
    showSearchPreview,
  } = useSearch();

  const pathname = usePathname();
  const [searchboxFocused, setSearchboxFocused] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const searchBoxController = useAnimationControls();

  useEffect(() => {
    if (showSearchPreview) {
      searchBoxController.start({ top: 0 });
    } else {
      searchBoxController.start({ top: '5.3rem' });
    }
  }, [showSearchPreview, searchBoxController]);

  return (
    <>
      <MenuDrawer show={showDrawer} dismiss={() => setShowDrawer(false)} />

      <div
        className={`${
          isMobile() && showSearchPreview ? 'fixed' : 'sticky'
        } top-0 z-[50] w-full bg-brand-white`}
      >
        {/* Top bar */}
        <div className="top-0 z-40 mb-5 flex h-[9.5rem] w-full justify-between bg-brand-white sm:mx-auto sm:max-w-content sm:items-center lg:h-[8rem] lg:gap-[1.6rem]">
          {/* Left nav items */}
          {/* desktop */}
          <div className="mx-auto hidden max-w-content grow gap-[2.4rem] lg:flex">
            <Link href="/" className="flex items-center">
              <LogoIcon variant="default" width={180} />
            </Link>

            <Suspense
              fallback={<div className="absolute z-[90] h-[4.375rem] w-full" />}
            >
              <div className="relative w-full">
                <SearchBox
                  onBlur={() => setSearchboxFocused(false)}
                  onFocus={() => setSearchboxFocused(true)}
                  dismissOnBlur={true}
                  className="relative z-[45]"
                />

                {showSearchPreview && (
                  <InstantSearch searchClient={searchClient as SearchClient}>
                    <Configure
                      // @ts-ignore FIXME: not sure why hitsPerPage throws type error... see https://discourse.algolia.com/t/property-hitsperpage-does-not-exist-for-configure-hitsperpage/18231
                      hitsPerPage={isMobile() ? 3 : 5}
                    />
                    <SearchResultsPreview />
                  </InstantSearch>
                )}
              </div>
            </Suspense>
          </div>

          {/* mobile */}
          <div className="relative flex w-full flex-col items-center justify-between pl-[0.8rem] lg:hidden">
            <div className="flex w-full justify-between">
              <button
                type="button"
                aria-label="menu-trigger"
                onClick={() => setShowDrawer(true)}
                className="mr-[1.6rem] text-brand-black lg:hidden lg:w-fit"
              >
                {showDrawer ? <CloseIcon /> : <MenuIcon />}
              </button>
              <Link
                aria-label="link to home"
                href="/"
                className="flex items-center justify-start lg:w-1/3"
              >
                <LogoIcon variant="default" width={150} height={32} />
              </Link>

              <div className="mx-[1.6rem] flex grow items-center justify-end gap-[1.6rem] lg:w-fit lg:gap-[2rem]">
                <div className="flex items-center">
                  {!user || user.isAnonymous ? (
                    <div className="flex items-center gap-x-1">
                      <Button
                        type="text"
                        width="small"
                        href="/login"
                        ariaText="login"
                        leadingIcon={<PersonOutlineIcon />}
                      />
                      {user && <ShoppingCartButton displayCount={true} />}
                    </div>
                  ) : !showMobileSearchbar ? (
                    <div className="flex items-center gap-x-[2rem]">
                      <NotificationButton />

                      <UserMenu />

                      <ShoppingCartButton displayCount={true} />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <motion.div
              initial={{ top: '5.3rem' }}
              animate={searchBoxController}
              transition={{ duration: 0.3, ease: 'easeOut' }}
              className="absolute w-full pl-2 pr-4"
            >
              <Suspense fallback={<div className="h-[4.375rem] w-full" />}>
                <SearchBox
                  onMobileFocus={() => setSearchboxFocused(true)}
                  dismissOnBlur
                  showCloseButton={!!searchTerm}
                  onClearClick={() => setShowMobileSearchbar(false)}
                  onEnter={() => setShowMobileSearchbar(false)}
                />
              </Suspense>

              {isMobile() && showSearchPreview && (
                <div className="fixed h-screen w-full overflow-hidden bg-white">
                  <InstantSearch searchClient={searchClient as SearchClient}>
                    <Configure
                      // @ts-ignore FIXME: not sure why hitsPerPage throws type error... see https://discourse.algolia.com/t/property-hitsperpage-does-not-exist-for-configure-hitsperpage/18231
                      hitsPerPage={isMobile() ? 3 : 5}
                    />

                    <SearchResultsPreview />
                  </InstantSearch>
                </div>
              )}
            </motion.div>
          </div>
          {/* end left nav items */}

          {/* Right nav items */}
          {(!searchboxFocused || isLargeDesktop()) && (
            <div className="flex items-center gap-[0.4rem] font-medium text-brand-black lg:gap-[1.2rem]">
              {user && !user.isAnonymous ? (
                <div className="hidden gap-[0.8rem] lg:flex">
                  <div className="order-3">
                    <ShoppingCartButton displayCount={true} />
                  </div>
                  {/* favorites */}
                  <div className="flex gap-x-4">
                    <Link
                      href="/dashboard/favorites"
                      className="mt-3 w-full min-w-[4.4rem] p-4 text-brand-black transition-colors hover:text-brand-primary"
                      aria-label="/dashboard/favorites"
                    >
                      <HeartIcon />
                    </Link>

                    <NotificationButton />

                    <ChatButton isSupport={!!userDoc?.roles?.support} />

                    <div className="flex items-center justify-center">
                      <UserMenu />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="hidden gap-[0.8rem] lg:flex">
                    <Button
                      href={{
                        pathname: '/signup',
                        ...(pathname !== '/' && {
                          query: {
                            redirect: pathname,
                          },
                        }),
                      }}
                      type="text"
                      text="Sign up"
                      width="smaller"
                    />
                    <Button
                      href={{
                        pathname: '/login',
                        ...(pathname !== '/' && {
                          query: {
                            redirect: pathname,
                          },
                        }),
                      }}
                      type="text"
                      text="Login"
                      width="smaller"
                    />
                    <ShoppingCartButton displayCount={true} />
                  </div>

                  <div className="lg:hidden">
                    <UserIcon />
                  </div>
                </>
              )}
            </div>
          )}

          <div className="hidden items-center justify-center font-extrabold tracking-wider lg:flex">
            <Button
              text="SELL"
              type="secondary"
              width={'normal'}
              className="!rounded-[1rem]"
              href={
                userDoc?.account_activated ? '/create-listing' : '/how-it-works'
              }
              height="small"
            />
          </div>
        </div>

        <div className="h-px w-full bg-brand-lightest-gray" />

        <CategoryBar />

        <div className="h-px w-full bg-brand-lightest-gray" />
      </div>
    </>
  );
};

export default Navbar;
