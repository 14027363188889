import DashboardSideMenu from '@c/dashboard/DashboardSideMenu';
import BaseDrawer from '@c/drawers/BaseDrawer';
import { LogoutIcon } from '@c/icons';
import ShopMenu from '@c/menus/ShopMenu';
import ProfileItem from '@c/ProfileItem';
import Button from '@ui/Button';
import { useAuth } from 'context/AuthContext';
import Link from 'next/link';

interface MenuDrawer {
  show: boolean;
  dismiss: () => void;
}

const MenuDrawer = ({ show, dismiss }: MenuDrawer) => {
  const { user, userDoc } = useAuth();

  return (
    <div className="min-w-[100vw]">
      <div className="overflow-y-scroll">
        <BaseDrawer
          show={show}
          dismiss={() => {
            dismiss();
          }}
        >
          <div className="h-full w-full">
            <div className="w-full p-[1.6rem]">
              {user && !user.isAnonymous && userDoc && (
                <>
                  <ProfileItem dismiss={() => dismiss()} />

                  <Button
                    text={
                      userDoc.account_activated
                        ? 'List an Item'
                        : 'Become a seller'
                    }
                    width="fluid"
                    type="secondary"
                    onClick={() => dismiss()}
                    href={
                      userDoc.account_activated
                        ? '/create-listing'
                        : '/become-a-seller'
                    }
                  ></Button>
                </>
              )}
            </div>

            <DashboardSideMenu menuItemClicked={() => dismiss()} />
          </div>
        </BaseDrawer>
      </div>
    </div>
  );
};

export default MenuDrawer;
