import {
  BagIcon,
  DollarCircleIcon,
  NoteIcon,
  ShopIcon,
  SMSIcon,
  TaskIcon,
} from '@c/icons';
import { useNotifications } from './useNotifcations';

interface MenuItemType {
  label: string;
  icon?: React.ReactNode;
  badgeCount?: number;
}
export interface DropdownMenuItemType extends MenuItemType {
  onClick: () => void;
  href?: string;
}

export interface NavMenuItemType extends MenuItemType {
  href: string;
  onClick?: () => void;
}

const useMenuItems = () => {
  const menuItems: NavMenuItemType[] = [
    {
      label: 'Sell your gear',
      href: '/how-it-works',
    },
    {
      label: 'Seller fees',
      href: '/about',
    },
    {
      label: 'Why MX Locker?',
      href: '/about',
    },
    {
      label: 'MX Locker protection',
      href: '/about',
    },
    {
      label: 'Seller center',
      href: '/about',
    },
  ];

  const helpItems = [
    {
      label: 'About us',
      href: '/about',
    },
    // {
    //   label: 'Contact',
    //   href: '/contact',
    // },
    {
      label: 'FAQ',
      href: '/faq',
    },
    {
      label: 'Terms & Conditions',
      href: '/terms-and-conditions',
    },
  ];

  const { unreadCount, pendingOrdersCount, pendingOffersCount } =
    useNotifications();

  const accountItems: NavMenuItemType[] = [
    {
      label: 'Messages',
      href: '/dashboard/messages',
      icon: <SMSIcon />,
      badgeCount: unreadCount,
    },
    {
      label: 'Offers',
      href: '/dashboard/offers',
      icon: <DollarCircleIcon />,
      badgeCount: pendingOffersCount,
    },
    {
      label: 'Purchases',
      href: '/dashboard/purchases',
      icon: <BagIcon />,
    },
  ];

  const sellerAccountItems = [
    {
      label: 'Seller Dashboard',
      href: '/dashboard/shop-overview',
      icon: <ShopIcon />,
    },
    {
      label: 'Listings',
      href: '/dashboard/listings',
      icon: <NoteIcon />,
    },
    {
      label: 'Orders',
      href: '/dashboard/orders',
      icon: <TaskIcon />,
      badgeCount: pendingOrdersCount,
    },
  ];

  return {
    menuItems,
    helpItems,
    accountItems,
    sellerAccountItems,
  };
};

export default useMenuItems;
