import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"latin\"]}],\"variableName\":\"Font\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/FacebookPixel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/ProviderProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/CanonicalUrl.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/layouts/NavbarFooterLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ui/CookiesAndPrivacy.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ui/InstallAppBanner.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
