import dayjs from 'dayjs';
import { NotificationDocument } from '@models/notification';

export const groupNotifications = (notifications: NotificationDocument[]) =>
  notifications.reduce(
    (acc, notification) => {
      const timestamp = notification.created;
      const date = dayjs(timestamp);
      const today = dayjs().startOf('day');
      const thisWeek = dayjs().startOf('week');
      const lastWeek = dayjs().subtract(1, 'week').startOf('week');
      const isToday = date.isSame(today, 'day');
      const isThisWeek = date.isAfter(thisWeek);
      const isLastWeek = date.isAfter(lastWeek);
      if (isToday) {
        acc.today.push(notification);
      } else if (isThisWeek) {
        acc.thisWeek.push(notification);
      } else if (isLastWeek) {
        acc.lastWeek.push(notification);
      } else {
        acc.later.push(notification);
      }
      return acc;
    },
    {
      today: [] as NotificationDocument[],
      thisWeek: [] as NotificationDocument[],
      lastWeek: [] as NotificationDocument[],
      later: [] as NotificationDocument[],
    }
  );
