'use client';

import { CloseIcon } from '@c/icons';
import { isEU } from '@util/index';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';

const CookiesAndPrivacy = () => {
  const [showCookies, setShowCookies] = useState(false);
  useEffect(() => {
    try {
      const didDismiss =
        typeof window !== 'undefined' &&
        window.localStorage &&
        localStorage.getItem('dismissedCookies');
      const showCookies = !didDismiss && isEU();
      setShowCookies(showCookies);
    } catch (e) {
      console.error(e);
      // Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
      // The operation is insecure.
      // logError(e, 'showCookies');
    }
  }, []);

  if (!showCookies) return null;

  return (
    <div className="sticky bottom-4 z-50 ml-auto flex max-w-full flex-col gap-[0.8rem] rounded-brand bg-black p-[1.6rem] font-medium text-white shadow-lg sm:w-[50rem]">
      <div className="flex w-full justify-between">
        <h2>Cookies &#38; Privacy</h2>
        <button
          type="button"
          aria-label="Close"
          onClick={() => {
            localStorage.setItem('dismissedCookies', 'true');
            setShowCookies(false);
          }}
        >
          <CloseIcon />
        </button>
      </div>
      <p>
        We and our third-party providers use cookies to personalize your
        experience and measure analytics. By using our website and our services,
        you agree to our use of cookies as described in our
        <Link className="ml-2 underline" href="/privacy-policy">
          Privacy Policy.
        </Link>
      </p>
    </div>
  );
};

export default CookiesAndPrivacy;
