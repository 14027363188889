import SafeImage from '@ui/SafeImage';
import { StaticImageData } from 'next/image';

export type AppFeatureSectionType = {
  title: string;
  icon: StaticImageData;
  description: string;
};

export function AppFeatureSection({
  title,
  icon,
  description,
}: AppFeatureSectionType) {
  return (
    <div className="mt-[6rem] flex gap-[4.4rem] md:mt-0 md:max-w-[40rem] lg:max-w-[60rem]">
      <SafeImage src={icon} alt="AppStore" className="w-[4.4rem]" />
      <div className="flex-1">
        <div className="text-[2.2rem] font-semibold">{title}</div>
        <div className="text-[1.8rem] font-normal">{description}</div>
      </div>
    </div>
  );
}
