import { LogoutIcon } from '@c/icons';
import { Popover, PopoverButton } from '@headlessui/react';
import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import useMenuItems from '@util/hooks/useMenuItems';
import { useAuth } from 'context/AuthContext';
import Link from 'next/link';
import BaseMenu from './BaseMenu';

const UserMenu = () => {
  const { accountItems, sellerAccountItems } = useMenuItems();
  const { userDoc, logout } = useAuth();
  if (!userDoc) return null;
  return (
    <BaseMenu
      triggerElement={
        <>
          {/* mobile */}
          <div className="flex lg:hidden p-px">
            <div className="p-2">
              <Avatar showNotificationCount user={userDoc} size="extraSmall" />
            </div>
          </div>

          {/* desktop */}
          <div className="hidden lg:flex p-px">
            <div className="p-2">
              <Avatar showNotificationCount user={userDoc} size="extraSmall" />
            </div>
          </div>
        </>
      }
    >
      <div className="isolate flex w-[26rem] flex-col items-start rounded-2xl border-2 bg-brand-white font-medium shadow-lg ">
        <div className="flex w-full items-center justify-center p-4">
          <Button
            type="primary"
            text="Account Overview"
            href="/dashboard/my-profile"
          />
        </div>
        <hr className="w-full" />
        <div className="flex w-full flex-col items-start text-brand-black">
          {accountItems.map((item) => (
            <PopoverButton
              key={item.href}
              as={Link}
              href={item.href}
              className="w-full rounded-lg px-[1.5rem] py-4 hover:bg-brand-secondary-light"
            >
              <div className="flex w-full items-center gap-x-4">
                <div className="flex items-center gap-[1.6rem]">
                  {item.icon}
                  <p>{item.label}</p>
                </div>
                {!!item.badgeCount && item.badgeCount > 0 && (
                  <div className="relative">
                    <div
                      className={`absolute right-0 top-0 flex aspect-square h-[2rem] items-center justify-center rounded-full bg-brand-secondary text-[1.2rem] font-medium text-brand-white`}
                    />
                    <div
                      className={`relative flex aspect-square h-[2rem] items-center justify-center rounded-full bg-brand-secondary text-[1.2rem] font-medium text-brand-white`}
                    >
                      {item.badgeCount}
                    </div>
                  </div>
                )}
              </div>
            </PopoverButton>
          ))}
          {userDoc.account_activated && (
            <>
              <hr className="w-full" />
              {sellerAccountItems.map((item) => (
                <PopoverButton
                  key={item.href}
                  as={Link}
                  href={item.href}
                  className="w-full rounded-lg px-[1.5rem] py-4 hover:bg-brand-secondary-light"
                >
                  <div className="flex w-full items-center gap-x-4">
                    <div className="flex items-center gap-[1.6rem]">
                      {item.icon}
                      <p>{item.label}</p>
                    </div>
                    {!!item.badgeCount && item.badgeCount > 0 && (
                      <div className="relative">
                        <div
                          className={`absolute right-0 top-0 flex aspect-square h-[2rem] items-center justify-center rounded-full bg-brand-secondary text-[1.2rem] font-medium text-brand-white`}
                        />
                        <div
                          className={`relative flex aspect-square h-[2rem] items-center justify-center rounded-full bg-brand-secondary text-[1.2rem] font-medium text-brand-white`}
                        >
                          {item.badgeCount}
                        </div>
                      </div>
                    )}
                  </div>
                </PopoverButton>
              ))}
            </>
          )}
        </div>

        <hr className="w-full" />
        <Link
          className="w-full rounded-lg px-[1.5rem] py-4 hover:bg-brand-secondary-light"
          href="/"
          onClick={() => {
            logout();
          }}
        >
          <div className="flex items-center gap-[1.6rem]">
            <LogoutIcon />
            <p>Sign out</p>
          </div>
        </Link>
      </div>
    </BaseMenu>
  );
};

export default UserMenu;
