import {
  deleteNotifications,
  getNotifications,
  getRealtimeNotificationsQuery,
  markNotificationAsRead,
  NotificationDrawer,
} from '@features/notifications/';
import { NotificationDocument } from '@models/notification';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { onSnapshot } from 'firebase/firestore';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from './AuthContext';
import { useToastContext } from './ToastContext';

interface NotificationContext {
  isLoading: boolean;
  notifications?: NotificationDocument[];
  setShow: (show: boolean) => void;
  show: boolean;
  unreadNotificationsCount: number;
  markAsReadMutation: (ids: string[]) => void;
  deleteNotificationsMutation: ({
    ids,
    uid,
  }: {
    ids: string[];
    uid: string;
  }) => void;
}
const NotificationContext = createContext({} as NotificationContext);

export function useNotification() {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      'useNotification must be used within a NotificationProvider'
    );
  }
  return context;
}

export function NotificationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { userDoc } = useAuth();
  const queryClient = useQueryClient();
  const { showSuccessToast } = useToastContext();
  const [show, setShow] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: ['notifications', userDoc?.uid],
    queryFn: () => getNotifications(userDoc!.uid),
    enabled: !!userDoc?.uid,
  });

  const { mutate: markAsReadMutation } = useMutation({
    mutationFn: markNotificationAsRead,
    onMutate: async (data) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['notifications'] });

      //   // Snapshot the previous value
      const previousNotifications = queryClient.getQueryData([
        'notifications',
      ]) as NotificationDocument[] | undefined;

      // mark any selected notifications as read
      const newNotifications = previousNotifications?.map((notification) => {
        if (data.includes(notification.id)) {
          return {
            ...notification,
            read: true,
          };
        }
        return notification;
      });
      // Optimistically update to the new value
      queryClient.setQueryData(['notifications'], () => newNotifications);

      // Return a context object with the snapshotted value
      return previousNotifications;
    },
    onError: (err, variables, previousNotifications) => {
      // If the mutation fails, use the context we returned
      queryClient.setQueryData(['notifications'], previousNotifications);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications'],
      });
    },
  });

  const { mutate: deleteNotificationsMutation } = useMutation({
    mutationFn: deleteNotifications,
    onMutate: async (data) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['notifications'] });

      //   // Snapshot the previous value
      const previousNotifications = queryClient.getQueryData([
        'notifications',
      ]) as NotificationDocument[] | undefined;

      // remove any selected notifications
      const newNotifications = previousNotifications?.filter(
        (notification) => !data.ids.includes(notification.id)
      );
      // Optimistically update to the new value
      queryClient.setQueryData(['notifications'], () => newNotifications);

      // Return a context object with the snapshotted value
      return previousNotifications;
    },
    onError: (err, variables, previousNotifications) => {
      // If the mutation fails, use the context we returned
      queryClient.setQueryData(['notifications'], previousNotifications);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications'],
      });
    },
  });

  const [realtimeNotifications, setRealtimeNotifications] = useState<
    NotificationDocument[]
  >([]);
  const allNotifications = useMemo(
    () =>
      [...(data ?? []), ...realtimeNotifications].sort(
        (a, b) => b.created - a.created
      ),
    [data, realtimeNotifications]
  );
  const notificationsQuery = useMemo(
    () => (!userDoc?.uid ? null : getRealtimeNotificationsQuery(userDoc.uid)),
    [userDoc?.uid]
  );

  useEffect(() => {
    if (!notificationsQuery || userDoc?.roles?.support) return;
    const unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
      const notifications = snapshot.docs.map((doc) =>
        doc.data()
      ) as NotificationDocument[];
      setRealtimeNotifications((prevNotifications) => {
        // if there are any new notifications, show a toast
        if (prevNotifications.length < notifications.length) {
          const notification = notifications[0];
          showSuccessToast(`${notification.title} - ${notification.message}`);
        }
        return notifications;
      });
    });
    return () => {
      unsubscribe();
    };
  }, [notificationsQuery, showSuccessToast, userDoc?.roles?.support]);

  const unreadNotificationsCount = useMemo(
    () =>
      [...(data ?? []), ...realtimeNotifications].reduce((acc, curr) => {
        if (curr.read) return acc;
        return acc + 1;
      }, 0),
    [data, realtimeNotifications]
  );

  return (
    <NotificationContext.Provider
      value={{
        show,
        setShow,
        notifications: allNotifications,
        isLoading,
        unreadNotificationsCount,
        markAsReadMutation,
        deleteNotificationsMutation,
      }}
    >
      {children}
      <NotificationDrawer />
    </NotificationContext.Provider>
  );
}
