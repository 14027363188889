import { useShoppingCart } from 'context/ShoppingCartContext';
import { CartIcon } from '..';
import { useRouter } from 'next/navigation';
import { isMobile } from '@util/index';

const ShoppingCartButton = ({ displayCount }: { displayCount?: boolean }) => {
  const router = useRouter();
  const { getItemCount, setCartOpen } = useShoppingCart();
  const itemCount = displayCount ? getItemCount?.() : undefined;

  const handleClick = () => {
    if (!isMobile()) {
      router.push('/my-cart');
    } else {
      setCartOpen(true);
    }
  };

  return (
    <button
      onClick={handleClick}
      className="relative flex min-w-[4.4rem] items-center justify-center p-4 text-brand-black transition-colors hover:text-brand-primary"
      aria-label="shopping-cart"
    >
      {!!itemCount && itemCount > 0 && (
        <div className="absolute right-0 top-0 flex h-8 w-8 items-center justify-center rounded-full border-[.2rem] border-brand-white bg-brand-secondary text-[1rem] font-medium text-brand-white">
          {itemCount}
        </div>
      )}
      <CartIcon />
    </button>
  );
};

export default ShoppingCartButton;
